.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #0B248D; 
  color: white; 
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  padding: 10px; 
}

.header {
  font-size: 48px;
  color: white;
}

.text {
  display: inline-block;
  font-size: 24px;
  margin-top: 20px;
  white-space: pre-wrap;
  overflow: hidden;
  border-right: 0.15em solid black;
  animation: blink-caret 0.75s step-end infinite;
}

@media (max-width: 1024px) { 
  .header {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .text {
    font-size: 18px;
    border-right: none;
  }
}

@media (max-width: 480px) {
  .text {
    font-size: 14px;
    border-right: none;
  }
  .header {
    font-size: 24px;
  }
}

.red-text {
  color: red;
}
